import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Box,
  useMediaQuery,
  useTheme,
  Container,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import Logo from '../assets/img/jeevanfoods-logo-white.png';
import AeziTech from '../assets/img/aezi-logo.png';
import { styled } from '@mui/system';

const NavLink = styled('a')(({ theme }) => ({
  position: 'relative',
  fontFamily: '"Montserrat", sans-serif',
  color: 'white',
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  transition: 'color 0.4s',
  textDecoration: 'none',
  '&:hover': {
    color: '#185519',
    willChange: 'color',
    '& .MuiSvgIcon-root': {
      opacity: 1,
      visibility: 'visible',
      color: 'white',
    },
    '& span': {
      marginLeft: '2.5rem',
      '&::after': {
        width: '100%',
      },
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
    position: 'absolute',
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.4s, visibility 0.4s',
    willChange: 'opacity, visibility',
  },
  '& span': {
    position: 'relative',
    transition: 'margin 0.4s',
    willChange: 'margin',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-6px',
      width: 0,
      height: '2px',
      backgroundColor: 'white',
      transition: 'width 0.4s ease-out',
      willChange: 'width',
    },
  },
}));

const SocialLink = styled('a')(({ theme }) => ({
  color: 'white',
  fontSize: '1.5rem',
  transition: 'transform 0.4s',
  willChange: 'transform',
  '&:hover': {
    transform: 'translateY(-0.25rem)',
  },
}));

const NavBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  const menuItems = [
    { title: 'Home', path: '/', hash: 'carousel' },
    { title: 'Services', path: '/', hash: 'services' },
    { title: 'Products', path: '/products' },
    { title: 'Clients', path: '/', hash: 'clients' },
    { title: 'Reviews', path: '/allreviews' },
    { title: 'Contact', path: '/', hash: 'contact' },
  ];

  const handleNavigation = useCallback((item) => {
    if (item.hash) {
      if (location.pathname === '/') {
        const element = document.getElementById(item.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      } else {
        navigate(`/#${item.hash}`);
      }
    } else {
      navigate(item.path);
      window.scrollTo(0, 0);
    }
    if (isMobile) {
      handleDrawerToggle();
    }
  }, [location.pathname, navigate, isMobile, handleDrawerToggle]);

  const renderNavLink = useCallback((item) => (
    <NavLink onClick={() => handleNavigation(item)}>
      <NorthEastIcon />
      <span>{item.title}</span>
    </NavLink>
  ), [handleNavigation]);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateNavbar = () => {
      const currentScrollY = window.pageYOffset;

      if (currentScrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      if (currentScrollY < lastScrollY || currentScrollY <= 0) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      lastScrollY = currentScrollY;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateNavbar);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        backgroundColor: scrolled ? 'rgba(0, 0, 0, 0.9)' : 'transparent',
        boxShadow: 'none',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
        transform: visible ? 'translateY(0)' : 'translateY(-100%)',
        willChange: 'background-color, transform',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={Logo} width="100" height="50" alt="Logo" />
          </RouterLink>

          {isMobile ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {menuItems.map((item) => (
                <Box key={item.title} sx={{ 
                  mx: 2, 
                  color: 'white'
                  }}>
                  {renderNavLink(item)}
                </Box>
              ))}
              <Box sx={{ml: '5vh', display: 'flex', alignItems: 'center', gap: 2 }}>
                <SocialLink href="https://www.instagram.com/" target="_blank"><InstagramIcon /></SocialLink>
                <SocialLink href="https://www.linkedin.com/" target="_blank"><LinkedInIcon /></SocialLink>
                <SocialLink href="https://www.facebook.com/" target="_blank"><FacebookIcon /></SocialLink>
              </Box>
            </Box>
          )}
        </Toolbar>
      </Container>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': { 
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            color: 'white',
            padding: '6rem 3.5rem 4.5rem',
          },
        }}
      >
        
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ position: 'absolute', top: '1rem', right: '1.5rem', color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: '0', display: 'flex', justifyContent: 'start'}}>
          <img src={Logo} width="150" height="75" alt="Logo" />
        </Box>
        <List>
          <hr/>
          {menuItems.map((item, index) => (
            <ListItem key={item.title} sx={{ mb: 1, animation: `slideIn 0.4s ease-out ${index * 0.5}s forwards` }}>
              {renderNavLink(item)}
            </ListItem>
          ))}
        </List>
          <hr/>
        <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <a href="https://www.syedahmed.link" target="_blank" rel="noopener noreferrer">
            <img src={AeziTech} alt="AeziTech Logo" style={{ height: '5vh', marginTop: '2vh' }} />
          </a>
          <Typography variant="caption" display="block" sx={{ mt: 1, color: 'white', fontFamily: '"Montserrat", sans-serif' }}>
            Developed by AeziTech
          </Typography>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default React.memo(NavBar);