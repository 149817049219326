import React from 'react';
import { Box, Container, Grid, Typography, Link, styled } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from '../assets/img/jeevanfoods-logo-white.png';
import AeziTech from '../assets/img/aezi-logo.png';

const StyledLink = styled(Link)(({ theme }) => ({
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
    willChange: 'transform',
  },
}));

const StyledLogo = styled('img')({
  height: '20vh',
  filter: 'drop-shadow(10px 10px 51px rgba(0,0,0,1))',
  transition: 'filter 0.3s ease-in-out',
  '&:hover': {
    filter: 'drop-shadow(10px 10px 51px rgba(0,0,0,0.8))',
    willChange: 'filter',
  },
});

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        color: '#FFFFFF',
        pt: 4,
        pb: 4,
        background: '#1e201e',
        fontFamily: '"Montserrat", sans-serif'
      }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} sm={5} sx={{display: { xs: 'grid', sm: 'flex', marginRight: '2vh'}}}>
            <Box mb={2}>
              <StyledLogo src={Logo} alt="Postcrafts" />
              <Box display="flex" alignItems="start" mt={3}>
                <Typography variant="p" color="inherit" sx={{fontFamily: '"Montserrat", sans-serif'}}>
                  Jeevan Foods, established in 1988, is a leading exporter of Aseel dates, dry dates, Ajwain seeds, Himalayan salt, and fennel seeds. 
                  With over 35 years of experience, we have cultivated an extensive palm plantation network of over 100,000 trees and a vast farming 
                  network for Ajwain seeds, ensuring a steady supply of premium-quality products.
                  </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Grid item xs={6} sm={12}>
              <Typography variant="h6" color="inherit" gutterBottom sx={{fontFamily: '"Montserrat", sans-serif', marginBottom: '1vh', fontWeight: 'bold'}}>
                Company
              </Typography>
              <Box>
                <StyledLink href="#" color="inherit" display="block" mb={1} sx={{textDecoration: 'none', fontFamily: '"Montserrat", sans-serif'}}>About</StyledLink>
                <StyledLink href="#" color="inherit" display="block" sx={{textDecoration: 'none', fontFamily: '"Montserrat", sans-serif'}}>Career</StyledLink>
              </Box>
            </Grid>
            <Grid item xs={6} sm={12} sx={{marginTop: '4vh'}}>
              <Typography variant="h6" color="inherit" gutterBottom sx={{fontFamily: '"Montserrat", sans-serif', marginBottom: '1vh', fontWeight: 'bold'}}>
                Help
              </Typography>
              <Box sx={{marginBottom: '3vh'}}>
                <StyledLink href="#" color="inherit" display="block" mb={1} sx={{textDecoration: 'none'}}>Terms & Conditions</StyledLink>
                <StyledLink href="#" color="inherit" display="block" sx={{textDecoration: 'none'}}>Privacy Policy</StyledLink>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit" gutterBottom sx={{fontFamily: '"Montserrat", sans-serif',  marginBottom: '2vh', fontWeight: 'bold'}}>
              Visit us
            </Typography>
            <Box component="form" noValidate>
               <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.4125630913422!2d68.85307182602058!3d27.673640976976195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3935d525acdba1bb%3A0x7577a6dd987a16ae!2sSukkur%20khajoor%20Mandi!5e0!3m2!1sen!2s!4v1723886049548!5m2!1sen!2s"
                width="100%"
                height="100%"
                style={{ border: 0, borderRadius: '1vh'}}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              />
            </Box>
            <Box display="flex" alignItems="center" mt={3}>
              <StyledLink href="#" color="inherit" sx={{ mr: 2 }}><TwitterIcon /></StyledLink>
              <StyledLink href="#" color="inherit" sx={{ mr: 2 }}><FacebookIcon /></StyledLink>
              <StyledLink href="#" color="inherit" sx={{ mr: 2 }}><InstagramIcon /></StyledLink>
            </Box>
          </Grid>
        </Grid>
        <hr/>
        <Box mt={3}>
          <Typography variant="body1" align='center' sx={{
            color: '#C7C7C7',
            display: 'flex',
            justifyContent: {
              xs: 'center',
              sm: 'space-between'
            },
            flexDirection: {
              xs: 'column',
              sm: 'row'
            }
          }}>
            <Typography variant='body1' align='center' marginBottom='2vh'>
              © Copyright 2024, All Rights Reserved by Jeevan Foods.
            </Typography>
            <Typography variant='body1'>
              Developed by: 
              <a href="https://www.syedahmed.link" target="_blank" rel="noopener noreferrer">
                <img src={AeziTech} alt="AeziTech Logo" style={{ height: '5vh', marginLeft: '2vh'}} />
              </a>
            </Typography>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default React.memo(Footer);