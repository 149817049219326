import React, { useState, useEffect, lazy, Suspense } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Logo from './assets/img/logo.png';
import Contact from './components/contact';

// Lazy load components
const LandingPage = lazy(() => import('./pages/landingPage'));
const AllProducts = lazy(() => import('./pages/Products'));
const AllReviews = lazy(() => import('./pages/allReview'));
const ProductData = lazy(() => import('./pages/ProductOverview'));

// Preloader component
const Preloader = () => (
  <motion.div 
    className="preloader-overlay"
    initial={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    style={{ willChange: 'opacity' }}
  >
    <div className="loader"></div>
  </motion.div>
);

function AnimatedRoutes() {
  const location = useLocation();
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <AnimatePresence mode="wait">
      <Suspense fallback={<Preloader />}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/products" element={<AllProducts />} />
          <Route path="/product/:productName" element={<ProductData />} />
          <Route path="/allreviews" element={<AllReviews />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <motion.div 
        className="App"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{ willChange: 'opacity' }}
      >
        <Navbar />
        <AnimatedRoutes />
        <Contact id="contact"/>
        <Footer id="footer" />
        <FloatingWhatsApp
          phoneNumber='+923358311083'
          accountName='Jeevan Foods'
          avatar={Logo}
          placeholder='Hi, I would like to know more'
          darkMode={true}
          allowClickAway={true}
          allowEsc={true}
          messageDelay={1}
        />
        <AnimatePresence>
          {loading && <Preloader />}
        </AnimatePresence>
      </motion.div>
    </Router>
  );
}

export default App;